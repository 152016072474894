import Axios from 'axios';

export function callArtistDashboardsIndex(artistProfileId, queryParams = {}) {
  return Axios.get(`/api/v1/artist_profiles/${artistProfileId}/artist_dashboards`, { params: queryParams })
    .then(response => response.data)
    .catch(error => { throw error; });
}

export function callArtistDashboardsCareerMap(artistProfileId, queryParams = {}) {
  return Axios.get(`/api/v1/artist_profiles/${artistProfileId}/artist_dashboards/career_map`, { params: queryParams })
    .then(response => response.data)
    .catch(error => { throw error; });
}