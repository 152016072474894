export default {
    genre: {
      endpoint: 'genres',
      label: 'Genres',
      identifier: 'genre_ids',
      multi: true,
      referenceKey: 'genre',
    },
    mood: {
      endpoint: 'moods',
      label: 'Moods',
      identifier: 'mood_ids',
      multi: true,
      referenceKey: 'mood',
    },
    occasion: {
      endpoint: 'occasions',
      label: 'Occasions',
      identifier: 'occasion_ids',
      multi: true,
      referenceKey: 'occasion',
    },
  };
  