<template>
  <div class="seek-bar-wrapper" @mouseenter="isHovered = true" @mouseleave="isHovered = false" @click="handleClickSeek">
    <svg ref="progressSvg" width="100%" height="100%" viewBox="0 0 100 20" preserveAspectRatio="none">
      <defs>
        <linearGradient id="progressGradient" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
          <stop offset="0%" stop-color="#fb278d" />
          <stop offset="100%" stop-color="#42a5f5" />
        </linearGradient>
      </defs>

      <!-- Transparent track -->
      <path d="M 0 10 L 100 10" stroke="rgba(255, 255, 255, 0.0)" stroke-width="11" stroke-linecap="butt" />

      <!-- Progress bar -->
      <path :d="progressPath" stroke="url(#progressGradient)" stroke-width="11" stroke-linecap="butt" fill="none" />
    </svg>

    <!-- Circular Handle -->
    <div v-if="isHovered || isDragging" class="seek-handle" :style="{ left: progressHandleX + '%' }" @mousedown="startDrag"></div>
  </div>
</template>

<script>
export default {
  props: {
    currentSeek: Number, // Current time position of the song
    currentDuration: Number, // Total duration of the song
  },
  data() {
    return {
      isHovered: false,
      isDragging: false,
      sliderSeek: this.currentSeek,
    };
  },
  computed: {
    /** Computes the X position of the progress handle */
    progressHandleX() {
      if (!this.currentDuration) return 0;
      return (this.sliderSeek / this.currentDuration) * 100;
    },
    /** Generates a curved path for seek progress */
    progressPath() {
      if (!this.currentDuration) return "M 0 10 L 0 10";
      const progressX = (this.sliderSeek / this.currentDuration) * 100;
      return `M 0 10 L ${progressX} 10`;
    },
  },
  watch: {
    currentSeek(newVal) {
      if (!this.isDragging) {
        this.sliderSeek = newVal;
      }
    },
  },
  methods: {
    startDrag() {
      if (!this.currentDuration) return;
      this.isDragging = true;
      window.addEventListener("mousemove", this.dragSeek);
      window.addEventListener("mouseup", this.stopDrag);
    },
    dragSeek(event) {
      if (!this.isDragging) return;

      const seekBar = this.$refs.progressSvg.getBoundingClientRect();
      const posX = event.clientX - seekBar.left;
      const newSeek = Math.min(this.currentDuration, Math.max(0, (posX / seekBar.width) * this.currentDuration));

      this.sliderSeek = newSeek;
      this.$emit("update-seek", newSeek);
    },
    stopDrag() {
      if (!this.isDragging) return;
      this.isDragging = false;
      window.removeEventListener("mousemove", this.dragSeek);
      window.removeEventListener("mouseup", this.stopDrag);
    },
    handleClickSeek(event) {
      if (!this.currentDuration) return;

      const seekBar = this.$refs.progressSvg.getBoundingClientRect();
      const posX = event.clientX - seekBar.left;
      const newSeek = Math.min(this.currentDuration, Math.max(0, (posX / seekBar.width) * this.currentDuration));

      this.sliderSeek = newSeek;
      this.$emit("update-seek", newSeek);
    },
  },
};
</script>

<style scoped>
svg {
  display: block;
  width: 100%;
  height: 100%;
}

.player-bar__seek-control-wrapper {
  position: relative; /* Allows absolute positioning inside */
  height: 0px; /* Prevents it from affecting layout */
  overflow: visible; /* Ensures the seek bar isn't clipped */
}

.player-bar__seek-control-wrapper,
.seek-bar-wrapper {
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center; /* Ensures the bar stays centered */
}

.seek-bar-wrapper {
  position: relative;
  width: 100%;
  height: 15px;
  bottom: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 9;
}

.seek-handle {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1.2rem;
  height: 1.2rem;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}

.seek-bar-wrapper:hover .seek-handle {
  opacity: 1;
}

.seekbar {
  width: 100%;
  display: block;
  cursor: pointer;
  position: relative;
}

.seek-bar-wrapper svg {
  pointer-events: none; /* Allows clicks to pass through */
}

.seek-bar-wrapper path {
  pointer-events: none; /* Prevents blocking handle clicks */
}

.seek-bar-wrapper,
.seek-handle {
  user-select: none;
}

.seek-handle {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1.2rem; /* Adjust handle size */
  height: 1.2rem;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: auto; /* Allows interaction */
  transition: opacity 0.2s ease-in-out;
  opacity: 0; /* Initially hidden */
}

.seek-bar-wrapper:hover .seek-handle {
  opacity: 1; /* Show handle on hover */
}

circle {
  transition: opacity 0.2s ease-in-out;
}
</style>
