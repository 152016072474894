<template>
  <AtmoModal
    id="song-profile-modal"
    v-if="song && songProfileOptions && Object.keys(songProfileOptions).length"
    v-model="hasSong"
    title="Song Profile"
    customClass="song-profile-modal"
    size="large"
    :closeFunction="() => closeModal()"
  >
    <header class="atmo-modal__header">
      <ModalHeader heading="Edit Song Profile" marginBottom="2rem" />
    </header>

    <div v-if="isLoading" class="song-profile-modal__loading-container">
      <atmo-loading />
    </div>

    <form v-else class="atmo-form song-profile-modal__form" autocomplete="off" @submit.prevent="submitForm">
      <div class="song-profile-modal__form-header">
        <div class="song-profile-modal__song-name-badge">{{ song?.name || song?.fileName || "Untitled Song" }}</div>
      </div>

      <!-- Grid-based layout -->
      <div class="song-profile-modal__fields-grid">
        <div
          v-for="profileType in ['lyricsTheme', 'mood', 'occasion', 'genre', 'timeSignature', 'tempo', 'keySignature', 'tonality', 'instrumentation']"
          :key="profileType"
          :class="{ 'song-profile-modal__multi-select-group': songProfileTypes[profileType]?.multi }"
        >
          <label class="song-profile-modal__label" :for="profileType">
            <span>{{ songProfileTypes[profileType]?.label || profileType }}</span>
            <atmo-popover v-if="songProfileTypes[profileType]?.infoText" class="song-profile-modal__popover" placement="right" triggers="hover">
              <template #trigger>
                <img class="song-profile-modal__info-trigger" src="@/assets/images/icons/info.png" alt="info" />
              </template>
              <template #content>
                {{ songProfileTypes[profileType].infoText }}
              </template>
            </atmo-popover>
          </label>

          <!-- MULTI SELECT BADGES -->
          <template v-if="songProfileTypes[profileType]?.multi">
            <div class="song-profile-modal__multi-select">
              <!-- Dropdown for adding new options -->
              <div class="song-profile-modal__multi-select-dropdown">
                <select v-model="newSelections[profileType]" class="dropdown-group">
                  <option disabled value="">Select {{ profileType }}</option>
                  <option v-for="option in availableOptions(profileType)" :key="option.id" :value="option">
                    {{ option.name }}
                  </option>
                </select>
                <button type="button" class="atmo-button--small" @click="addNewSelection(profileType)" :disabled="!newSelections[profileType]">Add {{ profileType }}</button>
              </div>

              <!-- Show badges for selected items -->
              <div class="song-profile-modal__badge-container">
                <atmo-badge v-for="(item, index) in currentlySelected[profileType]" :key="item.id || index" :label="item.name" removable :onRemove="() => removeMultiSelectItem(profileType, index)" />
              </div>
            </div>
          </template>

          <!-- TEMPO -->
          <template v-else-if="profileType === 'tempo'">
            <input v-model="currentlySelected.tempo" type="text" inputmode="decimal" placeholder="Enter tempo (e.g. 120 or 120.5)" class="input-field" />
          </template>

          <!-- SINGLE SELECT -->
          <template v-else>
            <select :id="profileType" v-model="currentlySelected[profileType]" class="dropdown-group" :disabled="!songProfileOptions[profileType]?.length">
              <option value="" disabled>
                {{ songProfileOptions[profileType]?.length ? `Select ${profileType}` : `No ${profileType}s Available` }}
              </option>
              <option v-for="option in songProfileOptions[profileType]" :key="option.id" :value="option">
                {{ option.name }}
              </option>
            </select>
          </template>
        </div>
      </div>

      <div class="song-profile-modal__control-buttons">
        <button type="reset" class="atmo-button atmo-button--tertiary" @click="cancelAndClose()">Cancel</button>
        <button type="submit" class="atmo-button atmo-button--primary">Save</button>
      </div>
    </form>
  </AtmoModal>
</template>

<script>
import AtmoBadge from "@/components/atmo-badge";
import AtmoLoading from "@/components/atmo-loading";
import AtmoPopover from "@/components/atmo-popover";
import ModalHeader from "@/components/modal-header";

export default {
  components: {
    AtmoLoading,
    AtmoBadge,
    AtmoPopover,
    ModalHeader,
  },
  props: {
    song: { type: Object, required: true },
    songProfileTypes: { type: Object, required: true },
    songProfileOptions: { type: Object, required: true },
    closeModal: { type: Function, required: true },
    updateSongProfile: { type: Function, required: true },
  },
  data() {
    return {
      isLoading: false,
      currentlySelected: this.initialCurrentlySelected(),
      newSelections: {},
    };
  },
  computed: {
    hasSong: {
      get() {
        return Boolean(this.song);
      },
      set(newValue) {
        return newValue;
      },
    },
    songProfile() {
      return this.song.songProfile;
    },
  },
  methods: {
    initialCurrentlySelected() {
      const profile = this.song?.songProfile || {};
      const selected = {};

      Object.keys(this.songProfileTypes).forEach((typeKey) => {
        const config = this.songProfileTypes[typeKey];
        const options = this.songProfileOptions[typeKey] || [];

        if (typeKey === "tempo") {
          // Directly set tempo
          selected.tempo = profile.tempo || "";
        } else if (config.multi && config.referenceKey) {
          // Handle multi-selects like genre, mood, occasion
          const references = profile[`${typeKey}_references`] || [];
          selected[typeKey] = references.map((ref) => ref[config.referenceKey]).filter(Boolean);
        } else {
          // Handle single-selects
          const matchId = profile[config.identifier];
          selected[typeKey] = options.find((opt) => opt.id === matchId) || null;
        }
      });

      return selected;
    },
    addMultiSelectItem(type) {
      if (!this.currentlySelected[type]) this.$set(this.currentlySelected, type, []);
      this.currentlySelected[type].push(null);
    },
    removeMultiSelectItem(type, index) {
      this.currentlySelected[type].splice(index, 1);
    },
    cancelAndClose() {
      this.currentlySelected = this.initialCurrentlySelected();
      this.closeModal();
    },
    async submitForm() {
      this.isLoading = true;
      const songProfileProps = {};

      Object.keys(this.currentlySelected).forEach((type) => {
        const config = this.songProfileTypes[type];
        const value = this.currentlySelected[type];

        if (config?.multi) {
          songProfileProps[config.identifier] = value.map((item) => item?.id).filter(Boolean);
        } else if (type === "tempo") {
          songProfileProps.tempo = parseFloat(value);
        } else {
          songProfileProps[config.identifier] = value?.id ?? null;
        }
      });

      await this.updateSongProfile(this.song, songProfileProps);
      this.isLoading = false;
      this.closeModal();
    },
    availableOptions(type) {
      return (this.songProfileOptions[type] || []).filter((option) => !this.currentlySelected[type]?.some((sel) => sel?.id === option.id));
    },
    addNewSelection(type) {
      if (!this.currentlySelected[type]) this.$set(this.currentlySelected, type, []);
      this.currentlySelected[type].push(this.newSelections[type]);
      this.newSelections[type] = null;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";
@import "~@/assets/stylesheets/mixins";

.song-profile-modal {
  &__loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
  }

  .atmo-modal__header {
    width: 100%;
    font-size: 0.9em;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
  }

  &__song-name-badge {
    font-size: 1.2em;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  .input-field {
    padding: 8px;
    color: white;
    font-size: 0.9rem;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 5px;
    margin-right: 0.5rem;
  }

  &__form-header {
    margin-bottom: 2rem;
    font-size: 1rem;
    font-weight: bold;
  }

  &__fields-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    margin-bottom: 2rem;
  }

  &__label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__info-trigger {
    height: 14px;
    margin-left: 5px;
  }

  &__multi-select {
    display: flex;
    flex-direction: column;
  }

  &__multi-select-dropdown {
    margin-bottom: 1rem;
  }

  &__badge-container {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-bottom: 10px;
  }

  &__multi-select-dropdown {
    display: flex;
    gap: 10px;
    align-items: center;

    select {
      flex: 1;
    }
  }

  &__control-buttons {
    display: flex;
    margin-left: auto;

    :first-child {
      margin-right: 10px;
    }
  }

  &__popover {
    .popover {
      background-color: $atmo-purple--light;
      color: $atmo-purple--extra-dark;
      font-size: 0.9em;
      font-weight: 500;
    }
  }
}
</style>
