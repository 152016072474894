<template>
  <div class="career-map-dashboard" data-cy="artist-career-map">
    <atmo-back-link></atmo-back-link>
    <div class="career-map-dashboard__grid-container">
      <!-- Left Column -->
      <div class="career-map-dashboard__summary-container">
        <div class="career-map-dashboard__section-header">$100K Journey</div>

        <div class="career-map-dashboard__card">
          <p class="career-map-dashboard__metric-highlight stat-count">${{ careerMapData.total_revenue?.toLocaleString() || "0" }} / $100,000</p>
          <p class="text-sm">{{ careerMapData.goal_completion_percentage }}% complete</p>
        </div>

        <div class="career-map-dashboard__section-header">True Fans</div>
        <div class="career-map-dashboard__card">
          <p class="career-map-dashboard__metric-highlight stat-count">{{ careerMapData.true_fan_count?.toLocaleString() || "0" }} / 1,000</p>
        </div>

        <div class="career-map-dashboard__section-header">Revenue Per Fan</div>
        <div class="career-map-dashboard__card">
          <p class="career-map-dashboard__metric-highlight stat-count">${{ careerMapData.avg_revenue_per_fan?.toFixed(2) || "0.00" }}</p>
        </div>

        <div class="career-map-dashboard__section-header">Total Fans</div>
        <div class="career-map-dashboard__card">
          <p class="career-map-dashboard__metric-highlight stat-count">
            {{ careerMapData.fan_count?.toLocaleString() || "0" }}
          </p>
        </div>
      </div>

      <!-- Right Column -->
      <div class="career-map-dashboard__info-container">
        <div class="career-map-dashboard__section-header">What is your Career Map?</div>
        <div class="career-map-dashboard__card">
          <p>
            This dashboard helps you track your path to earning $100,000 per year from your fans. Based on the idea of 1,000 true fans each contributing $100 annually, we break down your revenue per
            fan, total supporters, and overall progress. Use these insights to understand where your income comes from, how engaged your audience is, and what it’ll take to hit your financial goals
            through merch, subscriptions, tips, and more.
          </p>
        </div>

        <div class="career-map-dashboard__section-header">How to Reach $100/Fan</div>
        <div class="career-map-dashboard__card">
          <ul class="career-map-dashboard__list">
            <li>🎧 Sell a digital album or EP for $10–15</li>
            <li>👕 Launch exclusive merch bundles ($25–40)</li>
            <li>📬 Offer fan club memberships or subscriptions ($5–10/month)</li>
            <li>💬 Host virtual meet-and-greets or Q&As ($10–20)</li>
            <li>🎟️ Sell tickets to livestreamed performances or events</li>
            <li>🎁 Allow fans to tip or support through donation links</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtmoBackLink from "@/components/atmo-back-link";
import { callArtistDashboardsCareerMap } from "@/helpers/axiosCalls";

export default {
  components: { AtmoBackLink },
  data() {
    return {
      careerMapData: {},
    };
  },
  created() {
    this.$store.commit("setHudHeader", "Career Map");
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const res = await callArtistDashboardsCareerMap(this.$route.params.artistProfileId);
        this.careerMapData = res;
      } catch (err) {
        console.error("Career Map API error:", err);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.career-map-dashboard {
  margin: 0 75px;
  height: 74vh;
  display: flex;
  flex-direction: column;

  .career-map-dashboard__grid-container {
    flex: 1;
    overflow: hidden;
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    padding: 2rem;
    background-color: rgba(96, 72, 117, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    height: 100%;
    max-height: 100%;
  }

  .career-map-dashboard__section-header {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
  }

  .career-map-dashboard__summary-container,
  .career-map-dashboard__info-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .career-map-dashboard__card {
    background: rgba(161, 125, 187, 0.6);
    padding: 1rem;
    border-radius: 10px;
  }

  .career-map-dashboard__metric-highlight.stat-count {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .career-map-dashboard__list {
    padding-left: 1.5rem;
    list-style: disc;
    li {
      margin-bottom: 0.6rem;
    }
  }
}
</style>
