<template>
  <div v-if="loadingSongProfileOptions || savingForm" class="atmo-album-form__loading-container">
    <atmo-loading />
  </div>
  <div v-else class="atmo-album-form__container">
    <song-profile-modal
      v-if="activeSongForProfileModal && songProfileOptions"
      :key="activeSongForProfileModal && activeSongForProfileModal.id"
      :song="activeSongForProfileModal"
      :song-profile-types="songProfileTypes"
      :song-profile-options="songProfileOptions"
      :close-modal="closeSongProfileModal"
      :update-song-profile="updateSongProfile"
    />
    <form class="atmo-form atmo-album-form" autocomplete="off" @submit.prevent="submitForm">
      <atmo-steps>
        <atmo-step :active="currentPage === 'album-info'" :complete="currentPage !== 'album-info'"> Album Information </atmo-step>
        <atmo-step-divider :active="currentPage !== 'album-info'" />
        <atmo-step :active="currentPage !== 'album-info'"> Song Uploads </atmo-step>
      </atmo-steps>
      <div v-show="currentPage === 'album-info'" class="atmo-album-form__main-content">
        <div class="atmo-album-form__sidebar">
          <atmo-upload-card key="album-image" :image-src="existingAlbumImageSrc" :is-dropzone="true" :on-files-selected="onAlbumImageSelected" :max-kb="600" alt="Upload Album Image">
            Album Image
          </atmo-upload-card>
        </div>
        <div class="atmo-album-form__main-column">
          <div class="form-group">
            <label for="albumTitle">Album title</label>
            <input id="albumTitle" v-model="formAlbumTitle" type="text" placeholder="Hello Album" class="form-control input-field" />
          </div>
          <div class="form-group">
            <label for="yearOfProduction">Year of Production</label>
            <input id="yearOfProduction" v-model="formYearOfProduction" type="text" :placeholder="currentYear" class="form-control input-field" />
          </div>

          <!-- Album Profile Multi-Selects -->
          <div class="form-group">
            <div class="song-profile-modal__fields-grid">
              <div v-for="type in ['genre', 'mood', 'occasion']" :key="type" class="song-profile-modal__multi-select">
                <label class="song-profile-modal__label">
                  {{ albumProfileTypes[type].label }}
                </label>

                <div class="song-profile-modal__multi-select-dropdown">
                  <select v-model="newAlbumProfileSelections[type]" class="dropdown-group">
                    <option disabled value="">Select {{ type }}</option>
                    <option v-for="option in availableAlbumOptions(type)" :key="option.id" :value="option">
                      {{ option.name }}
                    </option>
                  </select>
                  <button type="button" class="atmo-button--small" @click="addAlbumProfileSelection(type)" :disabled="!newAlbumProfileSelections[type]">Add {{ type }}</button>
                </div>

                <div class="song-profile-modal__badge-container">
                  <atmo-badge v-for="(item, index) in albumProfileSelections[type]" :key="item.id || index" :label="item.name" removable :onRemove="() => removeAlbumProfileSelection(type, index)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="currentPage !== 'album-info'" class="atmo-album-form__main-content">
        <div class="atmo-album-form__sidebar">
          <atmo-upload-card key="album-songs" alt="Upload Songs" icon="attachment--xl.png" icon-size="large" accept="audio/*" :allow-multiple-files="true" :on-files-selected="onSongsSelected">
            Upload Songs
          </atmo-upload-card>
        </div>
        <div class="atmo-album-form__main-column">
          <div class="atmo-album-form__main-column-label">Album's Songs</div>
          <div v-if="formAlbumSongs.length === 0" class="atmo-album-form__empty-songs">
            <img class="atmo-album-form__empty-songs-icon" src="@/assets/images/icons/music-squares.png" />
            <span class="atmo-album-form__empty-songs-message">You haven't uploaded any songs yet</span>
            <div class="atmo-album-form__empty-songs-cta">
              <span>Upload Now</span>
              <img src="@/assets/images/icons/upload-arrow.png" />
            </div>
          </div>
          <div v-else class="atmo-album-form__songs">
            <div v-for="song in formAlbumSongs" :key="song.fileName" class="atmo-album-form__song" style="cursor: pointer">
              <div class="atmo-album-form__song-filename-badge-container">
                <atmo-badge class="atmo-album-form__song-filename-badge" :label="song.fileName" removable :on-remove="() => confirmSongDelete(song)" />
              </div>
              <div class="atmo-album-form__song-length">{{ song.length || "--:--" }}</div>
              <input v-model="song.name" size="sm" class="form-control atmo-album-form__song-title input-field" type="text" placeholder="Song Title" />
              <div v-if="!isNew" @click="openSongProfileModal(song)">Edit Profile</div>
            </div>
          </div>
        </div>
      </div>
      <div class="atmo-album-form__control-buttons">
        <button type="button" :style="{ visibility: currentPage === 'album-info' ? 'hidden' : 'visible' }" class="atmo-button" @click="currentPage = 'album-info'">‹ Back</button>
        <button v-show="currentPage === 'album-info'" type="button" class="atmo-button atmo-button--primary" @click="currentPage = 'song-uploads'">Next ›</button>
        <button v-show="currentPage !== 'album-info'" :disabled="formAlbumSongs.length === 0" type="submit" class="atmo-button atmo-button--primary">
          <template v-if="isNew">Finish</template>
          <template v-else>Save</template>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Axios from "axios";
import SongProfileModal from "./song-profile-modal";
import AtmoSteps from "@/components/atmo-steps";
import AtmoStep from "@/components/atmo-steps/step";
import AtmoStepDivider from "@/components/atmo-steps/divider";
import AtmoUploadCard from "@/components/atmo-upload-card";
import AtmoBadge from "@/components/atmo-badge";
import AtmoLoading from "@/components/atmo-loading";
import songProfileTypes from "./song-profile-types";
import albumProfileTypes from "./album-profile-types";
import { callSongsCreate, callSongsUpdate, callAlbumsCreate, callAlbumsUpdate, callSongsDelete } from "@/helpers/axiosCalls";
import { Howl } from "howler";

export default {
  components: {
    SongProfileModal,
    AtmoSteps,
    AtmoStep,
    AtmoStepDivider,
    AtmoUploadCard,
    AtmoBadge,
    AtmoLoading,
  },
  props: {
    isNew: { type: Boolean, default: false },
    album: { type: Object, default: null },
    albumSongs: { type: Array, default: null },
  },
  data() {
    return {
      albumId: null,
      albumImageFile: null,
      loadingSongProfileOptions: true,
      songProfileTypes: {},
      songProfileOptions: null,
      existingAlbumImageSrc: null,
      formAlbumTitle: null,
      formYearOfProduction: null,
      formAlbumGenre: null,
      formAlbumSongs: [],
      activeSongForProfileModal: null,
      submitAttempted: false,
      savingForm: false,
      deletedSongIds: [],
      albumProfileTypes,
      albumProfileSelections: {},
      newAlbumProfileSelections: {},
      albumProfileOptions: {},
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear().toString();
    },
    artistProfileId() {
      return this.$route.params.artistProfileId;
    },
    currentPage: {
      get() {
        return this.$route.query.step || "album-info";
      },
      set(newValue) {
        this.$router.push({ query: { ...this.$route.query, step: newValue } });
      },
    },
  },
  created() {
    if (!this.isNew) this.setInitialFormProps();
    this.getSongAndAlbumProfileOptions();
  },
  methods: {
    availableAlbumOptions(type) {
      return (this.albumProfileOptions[type] || []).filter((option) => !this.albumProfileSelections[type]?.some((sel) => sel?.id === option.id));
    },
    addAlbumProfileSelection(type) {
      if (!this.albumProfileSelections[type]) this.$set(this.albumProfileSelections, type, []);
      this.albumProfileSelections[type].push(this.newAlbumProfileSelections[type]);
      this.newAlbumProfileSelections[type] = null;
    },
    removeAlbumProfileSelection(type, index) {
      this.albumProfileSelections[type].splice(index, 1);
    },
    initialAlbumProfileSelections() {
      const profile = this.album?.album_profile || {};
      const selected = {};

      Object.keys(this.albumProfileTypes).forEach((key) => {
        const config = this.albumProfileTypes[key];
        const references = profile[`${key}_references`] || [];

        selected[key] = references
          .map((ref) => {
            // Use nested object if available
            if (ref[config.referenceKey]) return ref[config.referenceKey];

            // Fallback: find by id
            const id = ref[`${key}_id`];
            return (this.albumProfileOptions[key] || []).find((opt) => opt.id === id);
          })
          .filter(Boolean);
      });

      return selected;
    },
    openSongProfileModal(song) {
      console.log("TESTING");
      this.activeSongForProfileModal = song;
    },
    formatDuration(seconds) {
      if (!seconds || isNaN(seconds)) return null;
      const mins = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60)
        .toString()
        .padStart(2, "0");
      return `${mins}:${secs}`;
    },
    setInitialFormProps() {
      const { id, image, name, genre } = this.album;
      const { year } = this.album.album_profile;

      this.albumId = id;
      this.existingAlbumImageSrc = image.url;
      this.formAlbumTitle = name;
      this.formYearOfProduction = year.name;
      this.formAlbumGenre = genre;
      this.formAlbumSongs = this.albumSongs.map((song) => ({
        uploaded: true,
        processed: true,
        id: song.id,
        fileName: song.name,
        name: song.name,
        length: this.formatDuration(song?.song_profile?.duration) || "--:--",
        songProfile: song.song_profile,
      }));

      this.albumProfileSelections = this.initialAlbumProfileSelections();
    },
    getSongAndAlbumProfileOptions() {
      this.songProfileTypes = songProfileTypes;
      this.albumProfileTypes = albumProfileTypes;

      const songProfileOptions = {};
      const albumProfileOptions = {};

      const songTypesWithEndpoints = Object.entries(this.songProfileTypes).filter(([, type]) => !!type.endpoint);
      const albumTypesWithEndpoints = Object.entries(this.albumProfileTypes).filter(([, type]) => !!type.endpoint);

      const songPromises = songTypesWithEndpoints.map(([label, type]) =>
        Axios.get(`/api/v1/${type.endpoint}`).then((response) => {
          songProfileOptions[label] = response.data;
        })
      );

      const albumPromises = albumTypesWithEndpoints.map(([label, type]) =>
        Axios.get(`/api/v1/${type.endpoint}`).then((response) => {
          albumProfileOptions[label] = response.data;
        })
      );

      Promise.all([...songPromises, ...albumPromises])
        .then(() => {
          this.songProfileOptions = songProfileOptions;
          this.albumProfileOptions = albumProfileOptions;

          if (this.album?.album_profile) {
            this.albumProfileSelections = this.initialAlbumProfileSelections();
          }
        })
        .catch((error) => {
          console.error("Error loading profile options:", error);
        })
        .finally(() => {
          this.loadingSongProfileOptions = false;
        });
    },
    onAlbumImageSelected(files) {
      this.albumImageFile = files[0];
    },
    getAudioDuration(file) {
      return new Promise((resolve, reject) => {
        const url = URL.createObjectURL(file);
        const sound = new Howl({
          src: [url],
          format: [file.type.split("/").pop()],
          preload: true,
          onload: () => {
            const duration = sound.duration();
            URL.revokeObjectURL(url);
            resolve(duration);
          },
          onloaderror: (id, error) => {
            URL.revokeObjectURL(url);
            reject(`Failed to load audio: ${error}`);
          },
        });
      });
    },
    async onSongsSelected(files) {
      for (const file of files) {
        let duration = "--:--";
        try {
          const seconds = await this.getAudioDuration(file);
          duration = this.formatDuration(seconds);
        } catch (error) {
          console.warn("Error reading audio duration:", error);
        }

        this.formAlbumSongs.push({
          originalFile: file,
          fileName: file.name,
          name: file.name.replace(/\.[^/.]+$/, ""),
          uploaded: false,
          processed: false,
          id: null,
          length: duration,
          songProfile: null,
        });
      }
    },
    async submitForm() {
      this.submitAttempted = true;

      if (!this.formAlbumTitle || !this.formYearOfProduction) {
        this.$toast("Missing required fields", { type: "error" });
        this.currentPage = "album-info";
        return;
      }

      if (!this.formAlbumSongs.every((song) => song.name)) {
        this.$toast("Missing song names", { type: "error" });
        return;
      }

      this.savingForm = true;

      try {
        const album = await this.saveAlbum();
        this.albumId = album.id;
        await this.updateOrCreateSongs();
        await this.deleteRemovedSongs();

        this.$router.push({
          name: "albums.show",
          params: { albumId: this.albumId },
        });
      } catch (error) {
        console.error(error);
        this.$toast("Error saving album", { type: "error" });
      } finally {
        this.savingForm = false;
        this.$toast("Album and songs saved successfully!", { type: "success" });
      }
    },
    async updateOrCreateSongs() {
      const formData = new FormData();

      for (const song of this.formAlbumSongs) {
        if (song.uploaded && song.id) {
          await callSongsUpdate(song.id, {
            name: song.name,
            album_id: this.albumId,
          });
        } else {
          formData.append("songs[][file]", song.originalFile);
          formData.append("songs[][name]", song.name);
          formData.append("songs[][album_id]", this.albumId);
          formData.append("songs[][artist_profile_id]", this.artistProfileId);
        }
      }

      if (formData.has("songs[][file]")) {
        await callSongsCreate(formData, { bulk: true });
      }
    },
    async saveAlbum() {
      const formData = new FormData();

      // Add genre_ids, mood_ids, occasion_ids
      Object.keys(this.albumProfileTypes).forEach((type) => {
        const config = this.albumProfileTypes[type];
        const ids = this.albumProfileSelections[type]?.map((item) => item.id).filter(Boolean);
        ids?.forEach((id) => {
          formData.append(`${config.identifier}[]`, id);
        });
      });

      if (this.albumImageFile) formData.append("image", this.albumImageFile);
      formData.append("name", this.formAlbumTitle);
      formData.append("year", this.formYearOfProduction);

      if (this.formAlbumGenre?.id) {
        formData.append("genre_id", this.formAlbumGenre.id);
      }

      return this.isNew ? await callAlbumsCreate(this.artistProfileId, formData) : await callAlbumsUpdate(this.albumId, formData);
    },
    closeSongProfileModal() {
      this.activeSongForProfileModal = null;
    },
    removeSong(song) {
      if (song.uploaded && song.id) {
        this.deletedSongIds.push(song.id);
      }

      const index = this.formAlbumSongs.findIndex((formSong) => formSong.fileName === song.fileName);
      if (index !== -1) this.formAlbumSongs.splice(index, 1);
    },
    async confirmSongDelete(song) {
      const confirmed = window.confirm(`Are you sure you want to remove "${song.name}" from the album?`);
      if (!confirmed) return;

      if (song.uploaded && song.id) {
        this.deletedSongIds.push(song.id);
      }

      const index = this.formAlbumSongs.findIndex((formSong) => formSong.fileName === song.fileName);
      if (index !== -1) this.formAlbumSongs.splice(index, 1);
    },
    async deleteRemovedSongs() {
      for (const songId of this.deletedSongIds) {
        try {
          await callSongsDelete(songId);
        } catch (error) {
          console.error(`Failed to delete song ID ${songId}:`, error);
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-album-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  width: 95%;

  &__loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__main-content {
    display: flex;
    width: 100%;
    min-height: 300px;
    margin-top: 25px;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    max-width: 185px;

    .atmo-upload-card__container {
      min-height: 185px;
    }
  }

  &__main-column {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__main-column-label {
    text-transform: uppercase;
  }

  &__control-buttons {
    display: flex;
    width: 100%;
    margin-top: 20px;
    justify-content: space-between;
  }

  &__empty-songs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(white, 0.8);
    padding: 22px;
    margin-top: 20px;
    border-radius: 5px;
  }

  &__empty-songs-icon {
    height: 65px;
  }

  &__empty-songs-message {
    color: $atmo-purple--dark;
    margin: 10px;
  }

  &__empty-songs-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-size: 1.1em;

    img {
      height: 30px;
      margin-top: 10px;
    }
  }

  &__songs {
    display: flex;
    flex-direction: column;
  }

  &__song {
    display: flex;
    align-items: center;
    margin: 5px 0;

    &--disabled {
      opacity: 0.7;
      pointer-events: none;
    }
  }

  &__song-filename-badge-container {
    width: 10rem;
  }

  &__song-filename-badge {
    word-break: break-all;
  }

  $space-between-song-form-controls: 1rem;

  &__song-length {
    background-color: $atmo-purple--light;
    color: $atmo-purple--dark;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    border-radius: 5px;
    margin: 0 $space-between-song-form-controls;
    min-width: 5rem;
  }

  &__song-title {
    flex: 1;
    margin-right: $space-between-song-form-controls;
  }

  .form-group {
    margin-bottom: 1rem;

    label {
      margin-right: 1rem;
    }
  }

  .input-field {
    padding: 8px;
    color: white;
    font-size: 0.9rem;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 5px;
    margin-right: 0.5rem;
  }
}
</style>
