export default {
  genre: {
    endpoint: 'genres',
    identifier: 'genre_ids',
    label: 'Genre',
    multi: true,
    referenceKey: 'genre'
  },
  lyricsTheme: {
    endpoint: 'lyrics_themes',
    identifier: 'lyrics_theme_id',
    placeholder: 'Select Lyrics Theme',
    label: 'Lyrics Theme',
    infoText: `What is the main theme of the song's lyrics?`
  },
  mood: {
    endpoint: 'moods',
    identifier: 'mood_ids',
    label: 'Mood',
    multi: true,
    referenceKey: 'mood'
  },
  occasion: {
    endpoint: 'occasions',
    identifier: 'occasion_ids',
    label: 'Occasion',
    multi: true,
    referenceKey: 'occasion'
  },
  timeSignature: {
    endpoint: 'time_signatures',
    identifier: 'time_signature_id',
    placeholder: 'Select Time Signature',
    label: 'Time Signature',
    infoText: `Common time signatures are 4/4 and 3/4`
  },
  songForm: {
    endpoint: 'song_forms',
    identifier: 'song_form_id',
    placeholder: 'Select Song Form',
    label: 'Song Form',
    infoText: `Most modern pop songs are Verse/Chorus/Bridge`
  },
  tempo: {
    type: 'number',
    identifier: 'tempo',
    placeholder: 'Song Tempo',
    label: 'Tempo (BPM)',
    infoText: `How many beats occur per minute? (BPM)`
  },
  keySignature: {
    endpoint: 'song_keys',
    identifier: 'song_key_id', // ✅ FIXED
    placeholder: 'Select Key Signature',
    label: 'Key Signature',
    infoText: `If your song has multiple key signatures, list the most prominent one`
  },
  tonality: {
    endpoint: 'song_tonalities',
    identifier: 'song_tonality_id',
    placeholder: 'Select Song Tonality',
    label: 'Tonality',
    infoText: `If your song has multiple tonalities, list the most prominent one`
  },
  instrumentation: {
    endpoint: 'instrumentations',
    identifier: 'instrumentation_id',
    placeholder: 'Select Instrumentation',
    label: 'Instrumentation'
  },
  voxQualities: {
    endpoint: 'vox_qualities',
    label: 'Vocals?'
  }
};
